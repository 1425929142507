import { ref } from 'vue'
import { useMobile } from './mobile'

const { isMobile } = useMobile()

export const currentTab = ref('')

export const setCurrentTab = (selectedTab: string):void => {
    currentTab.value = selectedTab
}

export const listingsTabs = ref<HTMLElement | null>(null)

export const listingsTabsContent = ref<HTMLElement | null>(null)

export const getStyle = (element: HTMLElement, style: keyof CSSStyleDeclaration): number => {
    return parseFloat(getComputedStyle(element)[style] as string) || 0
}

export const hideHeaderWhenScrollToTab = ref(false)

export const setHideHeaderWhenScrollToTab = (value: boolean):void => {
    hideHeaderWhenScrollToTab.value = value
}

export const scrollToTab = (): void => {
    if (listingsTabsContent.value && listingsTabs.value) {
        const tabsContentTopPosition = listingsTabsContent.value.getBoundingClientRect().top + window.scrollY
        const scrollOffset = listingsTabs.value.getBoundingClientRect().height + getStyle(listingsTabsContent.value as HTMLElement, 'marginTop')
        const targetPosition = tabsContentTopPosition - scrollOffset

        setHideHeaderWhenScrollToTab(true)

        window.scrollTo({ top: targetPosition, behavior: 'smooth' })
    }
}

export const showHeaderAfterAnimation = (): void => {
    if(listingsTabsContent.value && listingsTabs.value){
        const tabsContentTopPosition = listingsTabsContent.value.getBoundingClientRect().top + window.scrollY
        const scrollOffset = listingsTabs.value.getBoundingClientRect().height + getStyle(listingsTabsContent.value as HTMLElement, 'marginTop')
        const targetPosition = tabsContentTopPosition - scrollOffset

        const handleScroll = (): void => {
            if (Math.abs(window.scrollY - targetPosition) < 1) {
                let scrollOffsetThreshold: number

                if (isMobile.value) {
                    scrollOffsetThreshold = 18
                } else {
                    scrollOffsetThreshold = 24
                }

                const checkScrollDistance = (): void => {
                    if (Math.abs(window.scrollY - targetPosition) > scrollOffsetThreshold) {
                        setHideHeaderWhenScrollToTab(false)
                        window.removeEventListener('scroll', checkScrollDistance)
                    }
                }

                window.addEventListener('scroll', checkScrollDistance)
                window.removeEventListener('scroll', handleScroll)
            }
        }

        window.addEventListener('scroll', handleScroll)
    }
}